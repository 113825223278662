import React from "react";

const SubFooter = (props) => {
  return (
    <div className="sub-footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="sub-footer-inner mt-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
