import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import MenuMobile from "./MenuMobile";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = (menuActive) => {
    this.setState((prevState) => ({
      menuActive: !prevState.menuActive,
    }));
  };

  increaseFont = () => {
    const style = window
      .getComputedStyle(document.body, null)
      .getPropertyValue("font-size");
    const currentSize = parseFloat(style);
    document.body.style.fontSize = currentSize + 5 + "px";
  };

  decreaseFont = () => {
    const style = window
      .getComputedStyle(document.body, null)
      .getPropertyValue("font-size");
    const currentSize = parseFloat(style);
    document.body.style.fontSize = currentSize - 5 + "px";
  };

  toggleContrast = () => {
    var elements = document.getElementsByTagName("*");

    for (var i = 0, max = elements.length; i < max; i++) {
      var e = elements[i];
      if (e.style.background == "black") {
        location.reload();
      }
      e.style.background = "black";
      e.style.color = "white";
    }
  };

  render() {
    const config = this.props.data.configJson;
    return (
      <>
        <div className="header">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img
                  height={config.logo.desktop_height}
                  alt={config.logo.alt}
                  src={config.logo.desktop}
                />
              </Link>
            </div>
            <div className="logo-mobile">
              <Link to="/">
                <img
                  height={config.logo.mobile_height}
                  alt={config.logo.alt}
                  src={config.logo.mobile}
                />
              </Link>
            </div>
            <MenuMobile active={this.state.menuActive} />
            <Menu />
            <Hamburger toggleMenu={this.toggleMenu} />
          </div>
        </div>
        <div className="visual-aid-btn-group">
          <button className="visual-aid-btn" onClick={this.increaseFont}>
            + Increase
          </button>
          <button className="visual-aid-btn" onClick={this.decreaseFont}>
            - Decrease
          </button>
          <button className="visual-aid-btn" onClick={this.toggleContrast}>
            Contrast
          </button>
        </div>
      </>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        configJson {
          logo {
            alt
            desktop
            mobile
            desktop_height
            mobile_height
          }
        }
      }
    `}
    render={(data) => <Header data={data} />}
  />
);
